.Error {
  background-color: var(--error-color) !important;
  color: #fff !important;
}

.Tabs {
  margin: 1rem 0;
  width: fit-content !important;

  :global(.MuiTabs-indicator) {
    display: none !important;
  }

  :global(.MuiButtonBase-root) {
    height: inherit !important;
    padding: 0.7rem 1rem !important;

    @media screen and (max-width: 600px) {
      height: 44px !important;
      padding: 0.7rem 0.7rem !important;
    }
  }

  &.text {
    :global(.MuiButtonBase-root) {
      max-width: 100%;
    }
  }

  &__container {
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
    width: 100%;
  }

  &.contained,
  &.containedCircular {
    border: 2px solid var(--border-color);
    border-radius: 8px;

    :global(.MuiTabs-flexContainer) {
      display: grid;
      justify-content: space-evenly;
    }

    :global(.MuiButtonBase-root) {
      max-width: 100%;
      font-size: var(--font-XXS);
      min-height: fit-content;
      padding: 0.4rem;

      @media screen and (max-width: 600px) {
        font-size: 12px;
        padding: 0.2rem;
        max-height: 44px;
      }
    }

    :global(.Mui-selected) {
      background-color: var(--progress-color) !important;
      color: white !important;
    }
  }

  &.containedCircular {
    border-radius: 80px;
    padding: 8px;
    :global(.MuiButtonBase-root) {
      border-radius: 80px;
    }
  }

  &.outlined {
    :global(.MuiTabs-flexContainer) {
      gap: 0.5rem;
      border-bottom: 2px solid var(--border-color);
    }

    :global(.MuiButtonBase-root) {
      max-width: 100%;
      font-size: var(--font-XXS);
      min-height: fit-content;
      padding: 0.4rem;
      background: var(--gray100);
      border-radius: 8px 8px 0px 0px;
      border: 2px solid var(--border-color);
      border-bottom: none;
      overflow: visible;
      color: var(--gray500);

      @media screen and (max-width: 600px) {
        font-size: 12px;
        padding: 0.2rem;
        max-height: 44px;
      }
    }

    :global(.Mui-selected) {
      background-color: transparent;
      color: var(--black-color) !important;
      position: relative;
      z-index: 9;

      &::before {
        content: '';
        height: 5px;
        position: absolute;
        bottom: -3px;
        z-index: 999999;
        width: 100%;
        background: white;
        overflow: auto;
      }
    }
  }
}

.Tab {
  text-transform: inherit !important;
  min-width: 150px;
  width: fit-content;

  @media screen and (max-width: 600px) {
    min-width: 50px;
  }

  &:global(.Mui-selected) {
    color: var(--progress-color) !important;
  }

  &__container {
    align-items: center;
  }

  &Content {
    height: 100%;
    width: 100%;
    display: grid;
  }
}

.Expand {
  background-color: var(--navy-700) !important;
  color: var(--white-color) !important;
  border-radius: 8px 8px 0px 0px;
  width: 78px !important;
}

.Expanded {
  position: absolute;
  top: 0 !important;
  padding: 0px 48px 24px 0px;
  background: white;
  transition: top 3s linear;
}
