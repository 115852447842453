.button {
    width: 100%;
    // height: 60px;
    text-transform: none;
    color: var(--white-color);
    // background-color: var(--bosscat-blue-600);
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // font-size: '18px';
    // line-height: '150%';
    font-family: 'LatoNormal';
    font-weight: 400;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}