.banner {
  text-align: left !important;
  margin-bottom: 3rem !important;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-radius: 3px;

  border-left-width: 5px;
  border-left-color: #4368f6;

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
}

.list {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  margin-bottom: 0 !important;
  margin-top: 0;
  font-family: 'pl-updated';
  font-size: 1rem;

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
}

.title {
  font-family: 'LatoNormal' !important;
  display: inline;
}

.subtitle {
  display: inline;
  margin-left: 0.3rem;
}
