.ImageBadge {
  position: relative;
  height: 100%;
  &__image {
    border-radius: 14px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__text {
    position: absolute;
    top: 1rem;
    left: 1rem;
    border-radius: 8px;
  }
  &__loading {
    position: absolute;
    top: 1rem;
    left: 1rem;
    border-radius: 8px;
  }
  &.primary {
    .ImageBadge {
      &__text {
        background-color: var(--green-100);
        padding: 0.5rem;
        box-shadow: 0px 18px 28px rgba(26, 42, 85, 0.15),
          0px 0px 1px rgba(26, 42, 85, 0.31);
      }
    }
  }
}
