.HomeToBeServiced {
  margin-left: -7px !important;
  width: calc(100% + 30px) !important;

  @media screen and (max-width: 1035px) {
    width: 100% !important;
    margin: 0 !important;
    display: block;

    :global(.MuiTypography-h5) {
      padding: 8px;
    }

    :global(.MuiGrid-root) {
      padding: 8px;
    }
  }
}

.addressContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1035px) {
    width: 100% !important;
    padding: 0 !important;
  }
}

.SelectAddress {
  > :global(.MuiGrid-root) {
    max-width: 100%;
    min-width: 100%;
  }

  :global(.MuiBox-root) {
    width: 100% !important;
  }

  @media screen and (max-width: 1035px) {
    width: 100% !important;
    margin: 0 !important;
    display: "block";
    padding: 0 !important;
  }

  &__title {
    font-size: 18px !important;
    font-family: "LatoBlack", sans-serif !important;
    color: "#0b060f"
  }
}

.unitNumber {

  @media screen and (max-width: 1035px) {
    width: 100% !important;
    margin: 0 !important;
  }
}