.HomeownerInformation {
  display: block !important;

  @media screen and (max-width: 1035px) {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 8px;
  }
}

.HomeownerFields {
  padding-right: 0px !important;

  @media screen and (max-width: 1035px) {
    width: 100% !important;
    margin: 0 !important;
    padding: 0px !important;
  }
}

.lastName {
  padding-right: 3px !important;
}

.email {
  padding-right: 3px !important;
}