.OutOfAreaModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: center;
  margin-bottom: 4rem;
  @media screen and (max-width: 1035px) {
    justify-content: flex-start;
  }
  &__title {
    font-family: 'LatoNormal';
    font-weight: 500;
  }
  &__text {
    font-family: 'LatoNormal' !important;
  }
  &__img {
    height: fit-content;
    @media screen and (max-width: 1035px) {
      transform: translateX(-15px);
    }
  }
  &__button {
    background-color: var(--green-700) !important;
    color: white !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    font-family: 'LatoNormal' !important;
    &__icon {
      width: 1rem;
    }
  }
}
