.riskScoreContainer {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    min-width: 480px;

    @media screen and (max-width: 480px) {
        min-width: 100%;
        width: 100%;
    }
}

.progressBar {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 16px;
    background: linear-gradient(to right, #34BB7A, #67CC9B, #FFC531, #CC4027);
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.marker {
    position: absolute;
    // top: 0;
    bottom: 0;
    width: 2px;
    height: 20px;
    border-radius: 8px;
    background-color: black;
    transition: left 0.3s ease;
}

.riskScoreText {
    font-size: 16px;
    color: var(--bosscat-black-400);
    margin: 0;
}

.score {
    font-weight: bold;
}