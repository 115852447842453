.applianceContainer {
    padding: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border: 2px solid #F5F6F7;
    // margin-bottom: 16px;
    border-radius: 16px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.name {
    font-size: 18px;
    font-family: 'LatoBold';
}

.brandName {
    font-size: 12px;
    color: var(--bosscat-black-500);
}

.headerLeft {
    display: flex;
    flex-direction: column;
}

.headerRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.ageInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ageInfoCol {
    display: flex;
    flex-direction: column;
}

.ageValue {
    font-size: 16px;
    // margin-bottom: 8px;
}

.ageLabel {
    font-size: 12px;
    color: var(--bosscat-black-400)
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}