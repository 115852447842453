@font-face {
  font-family: 'Outfit';
  src: url('./Outfit-SemiBold.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'OutfitBold';
  src: url('./Outfit-Bold.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'OutfitLight';
  src: url('./Outfit-Light.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'OutfitNormal';
  src: url('./Outfit-Regular.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'OutfitHeavy';
  src: url('./Outfit-Black.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LatoNormal';
  src: url('./Lato-Regular.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LatoBold';
  src: url('./Lato-Bold.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LatoBlack';
  src: url('./Lato-Black.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'LatoLight';
  src: url('./Lato-Light.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./Lato-Regular.ttf') format('openType');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
