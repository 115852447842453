.iconLabel {
    font-size: 18px !important;
    font-family: "NextArtBold" !important;
}

.riskLabel {
    font-size: 20px !important;
    font-family: "LatoBold" !important;
    margin-bottom: 12px;
}

.container {
    padding: 16px;
    min-width: 480px;

    @media screen and (max-width: 480px) {
        min-width: unset;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0 16px;
}


.estimatedLifeContainer {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: var(--bosscat-blue-100);
    border-radius: 8px;
    margin-top: 18px;
}

.estimatedLifeIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: var(--bosscat-blue-800);
}

.estimatedLifeText {
    font-size: 20px;
    color: var(--bosscat-blue-800);
}

.applianceData {
    margin-top: 16px;
}

.rowContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 48.5%;
    position: relative;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.errorMessage {
    font-size: 12px;
    color: var(--expired-color);
    margin-top: 4px;
    //     position: absolute;
    //     left: 0;
    //     bottom: -20px;
}

.navigationTabsStyle {
    width: 100% !important;
}

.fullWidth {
    width: 100%;
}

.inputLabel {
    font-size: 18px;
    font-family: "LatoBold" !important;
    margin-bottom: 4px;
}

.uploadImageContainer {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    border: 2px solid var(--bosscat-grey-400);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 8px 8px 8px;
    cursor: pointer;
}

.image {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    object-fit: cover;
}

.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.uploadIcon {
    width: 48px !important;
    height: 48px !important;
    color: #0B060F;
}

.uploadText {
    font-family: "NextArtBold" !important;
    font-size: 18px !important;
    margin: 8px 0;
}

.input {
    border: 2px solid #F5F6F7;
    margin-right: 0px !important;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    height: 100%;
}

.inputDisabled {
    pointer-events: none;
}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    gap: 16px;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
}

.deleteButton {
    background-color: var(--bosscat-grey-200);
}

.recallTitle {
    font-size: 16px;
    font-family: "NextArtBold" !important;
}

.recallRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.recallLabel {
    font-family: "LatoBold" !important;
    font-size: 18px;
}

.recallValue {
    font-size: 18px;
    color: var(--bosscat-black-400)
}

.recallLink {
    font-size: 16px;
    font-family: "LatoBold" !important;
    color: var(--bosscat-blue-600);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
}

.closeIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #FCFCFC;
    width: 40px;
    height: 40px;
    cursor: pointer;
}