.GiftSelector {
  border: 2px solid #ebecef;
  border-radius: 8px;
  flex-direction: row-reverse;
  padding: 1rem;
  width: 50%;
  justify-content: space-between;
  margin-right: 0 !important;
  margin-left: 0 !important;
  height: fit-content;

  @media screen and (max-width: 1035px) {
    padding: 16px;
  }

  &__title {
    font-family: 'OutfitHeavy' !important;
    color: #a5965a;
    text-transform: uppercase !important;
    // margin-bottom: 1.5rem !important;
    font-size: 30px !important;
  }

  &__root {
    margin-top: 1rem;

    @media screen and (max-width: 1035px) {
      flex-direction: column !important;
      flex-wrap: unset !important;
      justify-content: flex-end;
      padding: 0;
    }
  }

  &__image {
    @media screen and (min-width: 868px) {
      max-width: 100% !important;
    }

    @media screen and (max-width: 1035px) {
      flex-basis: 40% !important;
    }
  }

  &__label {
    gap: 0.2rem;
    align-items: center;
    min-height: 6.5rem;

    @media screen and (max-width: 1035px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  :global(.MuiButtonBase-root) {
    padding: 0 !important;
    height: fit-content !important;
  }

  @media screen and (max-width: 1035px) {
    align-items: flex-start !important;
  }

  &__container {
    display: flex;
    width: 100%;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    gap: 1rem;
    margin-top: 0.5rem;
    @media screen and (max-width: 1035px) {
      padding-bottom: 2rem;
      gap: 1rem;
    }
  }

  &__selected {
    border: 2px solid var(--bosscat-blue-600);
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 24px !important;

    @media screen and (min-width: 868px) {
      max-width: 100% !important;
    }

    @media screen and (max-width: 1035px) {
      margin-bottom: 32px !important;
      padding-right: 0px !important;
      flex-basis: 45% !important;
      justify-content: flex-start;
    }
  }

  // &__title {
  //   font-size: 16px !important;
  //   color: var(--input-color);
  //   line-height: 28px;
  //   margin-bottom: 0.5rem;
  // }

  &__subtitle {
    font-size: 14px !important;
    color: var(--input-color);
    font-family: 'LatoNormal' !important;
    line-height: 28px;
  }

  &__text {
    min-height: 180px;
    justify-content: center;

    @media screen and (max-width: 1035px) {
      margin-top: 1rem;
    }
  }

  &__list {
    margin: 0;
    margin-top: 1.5rem;
    // min-height: 150px;
    //padding: 0 0 0 inherit;
  }

  &__listItem {
    font-size: 14px;
    font-family: 'LatoNormal';
    color: #494a5a;
  }

  &__button {
    color: var(--bosscat-blue-600) !important;
    text-transform: inherit !important;
    text-decoration: underline !important;
    font-size: 14px !important;
    height: fit-content !important;
    padding: 0 0 0 0.4rem !important;

    :global(.MuiButton-label) {
      padding-top: 0;
    }
  }

  &__arrow {
    transform: rotate(45deg);
    color: var(--bosscat-blue-600);
  }
}
