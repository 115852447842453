.GiftBox {
  &__container {
    margin-top: 1rem !important;

    @media screen and (max-width: 1035px) {
      // padding: 1rem
      width: 100% !important;
      margin: 0 !important;
      padding: 0 1rem !important;
    }
  }

  &__imageContainer {
    margin: 0 !important;
    width: 100% !important;
  }

  &__image {
    @media screen and (max-width: 1035px) {
      width: 100%;
    }
  }
}