.GiftMessage {
  // background-color: var(--background-color-secondary);
  background-color: #f6f5ef;
  padding: 1rem;
  border-radius: 0.5rem;

  &__title {
    font-family: OutfitHeavy, sans-serif !important;
    font-size: 1rem;
    color: black;
    text-transform: uppercase;
  }

  // @media screen and (max-width: 1035px) {
  //   background-color: var(--white);
  //   padding: 8px 32px;
  //   margin-left: -16px;
  // }
  @media screen and (max-width: 375px) {
    padding: 8px 16px;
    margin-left: -8px;
  }

  &__container {
    @media screen and (max-width: 1035px) {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  &__picture {
    @media screen and (max-width: 1035px) {
      margin-right: 0rem !important;
    }
  }

  &__caption {
    display: block;
    font-family: 'LatoNormal' !important;
    color: #6d6a6f;
    margin-top: 1rem !important;

    @media screen and (max-width: 1035px) {
      margin-top: 0.3rem !important;
      margin-right: 1rem !important;
      margin-left: 1rem;
    }
  }

  &__message {
    flex: 1;
    display: flex;
    flex-direction: column;

    &_subtitle {
      font-family: LatoBold, sans-serif !important;
      font-size: 1rem;
      color: black;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    &__textarea {
      flex: 1;
      background-color: white !important;

      :global(.MuiInputBase-root) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      :global(.MuiInputBase-input) {
        min-height: 15rem;
        font-family: 'LatoLight';
      }
    }

    @media screen and (max-width: 1035px) {
      // margin-right: 1rem !important;
      margin-top: 2rem;
    }
  }
}
