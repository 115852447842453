@import url("./assets/fonts/fonts.css");


:root {
 /* Variables for colors definition */
 --white-color: #FFF;
 --black-color: #0F102C;
 --gray-color: #AAACBB;
 --background-color-primary: #FDFEFE; /*#E5E5E5;*/
 --background-color-secondary: #FCFCFC;
 --background-color-disabled: #F9F9F9;
 --button-secondary: #33C0FF;
 --button-secondary-hover: #0095D6;
 --small-button-color:  #1CCD83;
 --small-button-focus:  #0E915A;
 --button-focus-color: #2662C6;
 --border-color-focus:  #EFF0F1;
 --border-color:  #F5F6F7;
 --placeholder-color:  #9D9B9F;
 --input-color:  #0B060F;
 --text-color:  #5B5D71;
 --head-text-color:  #1A2A55;
 --row-focus: #E5F7FF;
 --row-active: #CEF0FF;
 --row-background: #FAFDFF;
 --line-color: #ADE6FF;
 --error-color: #FF9393;
 --teal-700: #0CA2B5;
 --profile-picture-button-bg: rgba(26, 42, 85, 0.5);
 --delete-button-color:  #EB5151;
 --button-bar: #F0F3FA;
 --date-color: #72748D;
 --primary-outlined-resting-border: rgba(11, 94, 170, 0.5);
 --better-color: #018847;
 --better-light-color: #01AA59;
 --better-bold-color: #016635;
 --better-delete-color: rgba(191, 39, 27, 1);
--punchlist-red: #FF5031;
--orange-300: #FFD874;
--green-700: #1CCD83;
--green-300: #B7F5DB;
--green-100: #EDFDF6;

  /*****react-tostify-override***********************/
 --toastify-color-info: rgb(232, 244, 253);
 --toastify-color-success: rgba(239, 247, 238, 1);
 --toastify-color-warning: rgb(255, 244, 229);
 --toastify-color-error: rgb(139, 109, 106);

 --toastify-toast-width: 400px;
 --toastify-toast-min-height: 64px;
 --toastify-toast-max-height: 800px;

 --toastify-text-color-info: rgb(13, 60, 97);
 --toastify-text-color-success: rgb(30, 70, 32);
 --toastify-text-color-warning: rgb(102, 60, 0);
 --toastify-text-color-error: rgb(97, 26, 21);
/**************************************************/

 /*****react-tag-colors***********************/
--expired-color: #E01F1F;
--expired-color-back: #FFF4F4;
--expired-color-back-active: #FFCCCC;
--completed-color: #17B975;
--completed-color-back: #EDFDF6;
--progress-color: #2F7BF7;
--progress-color-back: #FAFBFF;
--scheduling-color: #AD7D00;
--scheduling-color-back: #FFFBF0;
--hold-color: #3C2CA4;
--hold-color-back: #F1F0FF;
--dispatched-color: #FFFFFF;
--dispatched-color-back: #494A5A;
--approved-color: #0095D6;
--approved-color-back: #FAFDFF;
/**************************************************/
  /* booscat colors */

  --bosscat-black-200: #CECDCF;
  --bosscat-black-400: #6D6A6F;
  --bosscat-black-500: #3C383F;
  --bosscat-black-600: #0B060F;
  --bosscat-black-700: #09050C;
  --bosscat-black-1000: #091931;
  --bosscat-blue-100: #EAF2FE;
  --bosscat-blue-300: #ACCAFC;
  --bosscat-blue-600: #2F7BF7;
  --bosscat-blue-700: #2662C6;
  --bosscat-blue-800: #1C4A94;
  --bosscat-blue-900: #133163;
  --bosscat-blue-1000: #091931;
  --repairs-yellow-100: #FFF9EA;
  --repairs-yellow-500: #FFD15A;
  --repairs-yellow-600: #FFC531;
  --repairs-yellow-700: #CC9E27;
  --repairs-yellow-800: #99761D;
  --bosscat-green-100: #E6F7EE;
  --bosscat-green-500: #34BB7A;
  --bosscat-green-600: #01AA59;
  --bosscat-green-700: #018847;
  --bosscat-green-800: #016635;
  --bosscat-green-900: #004424;
  --hardware-haze-100: #F6F5EF;
  --hardware-haze-400: #C9C09C;
  --hardware-haze-600: #A5965A;
  --hardware-haze-700: #847848;
  --hardware-haze-1000: #211E12;
  --handyman-green-700: #1A3E2A;
  --bosscat-grey-200: #FCFCFC;
  --bosscat-grey-300: #F9F9F9;
  --bosscat-grey-400: #F5F6F7;
  --bosscat-grey-800: #8F9091;
  --bosscat-grey-1000: #303030;
  --renovation-red-100: #FFEEEA;
  --renovation-red-600: #FF5031;
  --renovation-red-700: #CC4027;
  --renovation-red-800: #99301D;
  --renovation-red-900: #662014;
  --handyman-green-600: #214E34;
  --handyman-green-900: #0D1F15;
  --border-border-error-light: #FFB9AD;
  --border-border-info-light: #ACCAFC;
  --border-border: #F5F6F7;
  --border-border-info: #2F7BF7;
  --text-text-primary: #0B060F;
  --text-text-info: #2F7BF7;
  --text-text-success: #01AA59;
  --text-text-tertiary: #9D9B9F;
  --text-text-warning: #CC9E27;
  --background-bg-soft-3: #F5F6F7
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color-primary);

  /*NoSelect*/
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*****hide-autofill********************************/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
/**************************************************/

/*****hide-number-arrows***************************/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}
/**************************************************/

/*****hide-blue-highlight**************************/
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/**************************************************/

/*****custom-scrollbar*****************************/
::-webkit-scrollbar {
  width: 20px;
  height: 18px;
}

.dark::-webkit-scrollbar {
  background-color: var(--background-dark);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 8px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 26px;
          border-radius: 26px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
          box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
/**************************************************/

/*****grecaptcha-override**************************/
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: -55px !important;
  opacity: 0.3;
}

.grecaptcha-badge:hover {
  left: 0px !important;
  opacity: 1;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}
/**************************************************/

/*****react-html5-camera-photo-override************/
@keyframes disabledCamera {
  0% {visibility: hidden;}
  75% {visibility: hidden;}
  100% {visibility: visible;}
}

#container-circles {
  position: absolute !important;
  left: 50% !important;
  bottom: 75px !important;
  animation: disabledCamera 1.5s !important;

}

#inner-circle,
#outer-circle {
  border-radius:50%;
}

#outer-circle {
  position:absolute;
  left: -37px !important;
  height: 64px !important;
  width: 64px !important;
  background-color: hsla(0,0%,100%,.4);
  z-index: 1;
  display: flex;
}

#inner-circle {
  left: 60% !important;
  top: 39px !important;
  height: 32px !important;
  width: 32px !important;
  background: #fff;
  margin: -22px 0 0 -22px !important;
  z-index: 2;
}
/**************************************************/

/*****react-google-maps/api-override***************/
/* Action Buttons
.gmnoprint { display:none }
.gm-style-cc { display:none }
.gmnoprint div { background:none !important; } 
*/

/* Google link */
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style .gm-style-iw-t::after {
  display: none !important;
}
/* Bottom line */
.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display:none;
}
/**************************************************/

/*****react-tostify-override***********************/
@media only screen and (max-width: 480px) {
  .Toastify__toast-theme--colored {
    margin: 16px;
  }
}

/* @media only screen and (max-width: 600px) {
  body {
    zoom: 0.5;
  }
}
@media only screen and (max-width: 800px) {
  body {
    zoom: 0.6;
  }
}
@media only screen and (max-width: 900px) {
  body {
    zoom: 0.8;
  }
}
@media only screen and (max-width: 1000px) {
  body {
    zoom: 0.9;
  }
}
@media only screen and (max-width: 1200px) {
  body {
    zoom: 1;
  }
}
@media only screen and (max-width: 1400px) {
  body {
    zoom: 1.1;
  }
}
@media only screen and (min-width: 1400px) {
  body {
    zoom: 1.25;
  }
}
@media only screen and (min-width: 2000px) {
  body {
    zoom: 1.5;
  }
}
@media only screen and (min-width: 2500px) {
  body {
    zoom: 1.8;
  }
}
@media only screen and (min-width: 3000px) {
  body {
    zoom: 2;
  }
} */

.Toastify__close-button > svg { 
  color: var(--black-color)
}
/**************************************************/


@keyframes loginAnimationFadeInOut {
  0% {
    opacity:1;
  }
  17% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  92% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

#loginAnimation img {
  animation-name: 'loginAnimationFadeInOut';
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-direction: alternate;
}

/**************************************************/

.rdrDefinedRangesWrapper {
  width: 0px !important;
}

/**************************************************/

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--bosscat-blue-600) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked:hover {
  color: var(--button-focus-color) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked:active {
  background-color: transparent !important;
}

/**************************************************/

.MuiTouchRipple-root {
  display: none;
}
.MuiTouchRipple-ripple {
  display: none;
}
.MuiTouchRipple-rippleVisisble {
  display: none;
}

/**************************************************/

.MuiPaper-elevation8 {
  box-shadow: 0px 3px 5px rgba(26, 42, 85, 0.2), 0px 0px 1px rgba(26, 42, 85, 0.31) !important;
  border: 2px solid #EBECEF !important;
  border-radius: 8px !important;
}

.MuiListItem-root {
  justify-content: flex-end !important;
}

.MuiList-padding {
  padding: 0px 0px !important;
}

/**************************************************/

.rdrDay{
  padding: 0.4rem;
}

.MuiButton-label{
  padding-top: 0 !important
}
.calendly-inline-widget{
  min-height: 65vh
}


.swiper-button-prev-slider:after{
  background-image: url(./assets/icons/arrow-prev.svg) !important;
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 12px;
}
.swiper-button-prev-slider, .swiper-button-next-slider{
  cursor: pointer;
}

.swiper-button-next:after{
  background-image: url(./assets/icons/arrow-next.svg) !important;
  background-repeat: no-repeat;
  content: '';
  width: 16px;
  height: 16px;
}
.swiper-button-prev:after{
  background-image: url(./assets/icons/arrow-prev.svg) !important;
  background-repeat: no-repeat;
  content: '';
  width: 16px;
  height: 16px;
}

.swiper-button-prev-slider:after{
  background-image: url(./assets/icons/arrow-next.svg) !important;
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 12px;
}

.slider-arrow{
  position: absolute;
  top: 50%;
  z-index: 99;
  width: fit-content;
}

.swiper-button-next-unique {
  transform: translate(0%, -50%);
  right: 0
}

.swiper-button-prev-unique{
  transform: translate(0%, -50%);
  left: 0
}
.swiper-button-next-slider2 {
  transform: translate(0%, -50%);
  right: 0
}

.swiper-button-prev-slider2{
  transform: translate(0%, -50%);
  left: 0
}
