.GiftShippingInformation {
  width: 100%;

  &__datePicker {
    position: relative;

    :global(.DatePicker) {
      bottom: 3.5rem;
    }

    :global(.MuiFormControl-root) {
      box-shadow: 0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 2px rgba(26, 42, 85, 0.31);
    }
  }

  @media screen and (max-width: 600px) {
    // padding: 1rem
  }

  &__title {
    &__icon {
      width: 15px;
      margin-right: 0.4rem;
    }

    &__text {
      text-transform: uppercase;
      font-family: OutfitHeavy, sans-serif !important;
      margin-bottom: 8px !important;
    }
  }

  &__receiver {
    border: 2px solid #ebecef;
    border-radius: 8px;
    flex-direction: row-reverse;
    padding: 8px;
    width: 100%;
    justify-content: space-between;
    margin-right: 0 !important;
    margin-left: 0 !important;

    &__title {
      font-size: 18px !important;
      font-family: 'LatoNormal';
    }

    &__container {
      display: flex;
      width: 100%;
      flex-direction: row !important;
      flex-wrap: nowrap !important;
      // gap: 1.5rem;
    }

    &__selected {
      border-color: var(--bosscat-blue-600);
    }
  }

  &__SelectAddress {
    :global(.MuiTextField-root) {
      width: 100%;
    }

    > :global(.MuiGrid-root) {
      max-width: 100%;
      padding-right: 0 !important;
      flex: 1;

      > :global(.MuiBox-root) {
        width: 100% !important;
      }

      :global(.MuiTypography-root) {
        margin-top: 0;
        font-family: 'LatoBlack' !important;
        font-size: 18px !important;
      }
    }

    @media screen and (max-width: 600px) {
      margin: 0px !important;

      :global(.MuiGrid-root) {
        padding: 0 !important;
      }
    }
  }

  &__input {
    :global(input) {
      font-family: 'Lato';
    }

    &__container {
      @media screen and (max-width: 600px) {
        padding-right: 8px !important;
      }

      :global(.MuiGrid-root) {
        @media screen and (max-width: 600px) {
          padding-right: 0px !important;
        }
      }
    }
  }

  &__image {
    &__container {
      // margin-top: -4rem;

      @media screen and (max-width: 600px) {
        margin-top: 0;
      }
    }
  }

  &__address {}
}

.caption {
  font-size: 16px !important;
  color: #6D6A6F;
}

.leftContent {
  margin-right: 16px;
}

.Content {
  display: flex;
}

.addressLabel {
  font-family: "LatoBlack" !important;
  font-size: 18px !important;

}

.inputContainer {
  padding-right: 0px !important;
}

.inputContainerFirst {
  max-width: none !important;
  width: calc(100% + 11px);
}